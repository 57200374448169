<template>
    <div class="main-box">
        <div class="con-box">
            <a-table :bordered="true" :columns="columns" :data-source="nsLists" :loading="loading" :pagination="pagination" @change="onPage" style="margin-top: 20px;">
                <template #action="{record}" v-if="nsLists.length != 0">
                    <img src="@/assets/images/edit.png" style="margin-right: 10px;" @click="$router.push({ path: '/ReportForms/NSPJDetail', query: { type: 'edit', id: record.JYDATA_NSPJ_ID, num: record.xuhao } })">
                    <a-popconfirm
                        title="确定要删除此条记录吗?"
                        ok-text="是"
                        cancel-text="否"
                        @confirm="onDel(record)"
                        @cancel="cancel"
                    >
                        <img src="@/assets/images/del.png">
                    </a-popconfirm>
                </template>
            </a-table>
            <div class="add-item">
                <div @click="$router.push({ path: '/ReportForms/NSPJDetail', query: { type: 'add', id: '', num: '' } })">
                    <img src="../../assets/images/add.png" style="margin-right: 5px;">增加一行
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
const columns = [
    { title: '序号', width: 100, dataIndex: 'xuhao', align: 'center', key: 'xuhao' },
    { title: '纳税人识别号', dataIndex: 'nashuirenshibiehao', align: 'center', key: 'nashuirenshibiehao' },
    { title: '省份', dataIndex: 'shengfen', align: 'center', key: 'shengfen' },
    { title: '年份', dataIndex: 'nianfen', align: 'center', key: 'nianfen' },
    { title: '评级', dataIndex: 'pingji', align: 'center', key: 'pingji' },
    {
        title: '操作',
        key: 'operation',
        align: 'center',
        fixed: 'right',
        width: 100,
        slots: { customRender: 'action' }
    }
]
export default {
    computed: {
        ...mapGetters([
            'orgId',
            'loginType',
            'isAuth',
            'addXXStatus'
        ])
    },
    watch: {
        addXXStatus: function (newVal) {
            if (newVal) {
                this.getNSPJList(new Date().getFullYear() - 1)
            }
        }
    },
    data () {
        return {
            labelCol: {
                span: 6
            },
            wrapperCol: {
                span: 16
            },
            columns,
            loading: false,
            nsLists: [],
            pagination: {},
            page: 1,
            pageCount: 10
        }
    },
    methods: {
        // 获取纳税评级列表
        getNSPJList (y) {
            this.loading = true
            this.nsLists = []
            this.$store.dispatch('getNSPJList', { showCount: this.pageCount, currentPage: this.page, ENTERPRISE_ID: this.orgId, YEAR: y, tm: new Date().getTime() }).then(res => {
                this.loading = false
                if (res.result === 'success') {
                    this.$store.commit('SET_XXSTATUS', false)
                    if (res.varList.length != 0) {
                        this.pagination.total = res.page.totalResult
                        let datas = res.varList
                        for (let i in datas) {
                            this.nsLists.push({
                                'key': parseInt(i) + 1,
                                'JYDATA_NSPJ_ID': datas[i].JYDATA_NSPJ_ID,
                                'xuhao': parseInt(i) + 1 + ((this.page - 1) * 10),
                                'nashuirenshibiehao': datas[i].NASHUIRENCODE,
                                'shengfen': datas[i].PROVINCE,
                                'nianfen': datas[i].YEAR,
                                'pingji': datas[i].PINGJI
                            })
                        }
                    } else {
                        this.nsLists = []
                        this.pagination.total = 0
                    }
                } else {
                    this.nsLists = []
                    this.pagination.total = 0
                    this.$message.error('获取列表失败，请稍后重试！')
                }
            })
        },
        // 翻页
        onPage (pagination) {
            this.page = pagination.current
            this.getNSPJList(new Date().getFullYear() - 1)
        },
        // 删除单条纳税评级
        onDel (item) {
            this.$store.dispatch('deleteNSPJItem', { JYDATA_NSPJ_ID: item.JYDATA_NSPJ_ID, tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                    this.$message.success('删除成功！')
                    this.getNSPJList(new Date().getFullYear() - 1)
                } else {
                    this.$message.success('删除失败，请稍后重试！')
                }
            })
        }
    },
    created () {
        if (this.loginType === 'qiye' && this.isAuth === 'N') {
            this.$message.info('请先完成企业认证或个体认证后才能进入')
            this.$router.push('/PersonalLayout/WareHouse')
            return false
        }
        this.getNSPJList(new Date().getFullYear() - 1)
    }
}
</script>
<style lang="less" scoped>
.ant-form ::v-deep(label){
    font-size: 12px;
}
::v-deep(.ant-form-item-label){
    overflow: visible;
}
.main-box{
    width: @main-width-base;
    margin:20px auto;
    .con-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
        .add-item{
            margin-top: 20px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }

    .pd-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
        .content{
            padding-top: 15px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            border-bottom: 1px solid #bbbbbb;
            .con-item{
                width: 50%;
            }
        }
    }
}
</style>
